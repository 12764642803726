import { PATH_IMG_SPORT_SCHEDULE, PATH_ICON_HOT_URL } from '~/constants/path'
export const FILTER_DEFAULT = {
  ALL: {
    text: 'Tất cả giải đấu',
    icon: `${PATH_IMG_SPORT_SCHEDULE}ball.svg`,
    value: 'all'
  },
  HOT: {
    text: 'Giải đấu HOT',
    icon: `${PATH_IMG_SPORT_SCHEDULE}hot.svg`,
    value: 'hot'
  }
}

export const TITLE = 'Lịch Thi Đấu'

export const NOTE = {
  MATCH_DETAIL: {
    icon: `${PATH_IMG_SPORT_SCHEDULE}icon-match.svg`,
    title: 'Soi kèo',
    desc: 'Xem đội hình & diễn biến trận đấu trực tiếp'
  },
  RATE: {
    icon: `${PATH_IMG_SPORT_SCHEDULE}icon-c.svg`,
    title: 'Kèo chấp, Tỉ lệ kèo chấp đa dạng',
    desc_1: 'Đội Chấp: Kèo trên',
    desc_2: 'Được Chấp: Kèo dưới',
    img: `${PATH_IMG_SPORT_SCHEDULE}note-c.webp`
  },
  COMPARISON: {
    icon: `${PATH_IMG_SPORT_SCHEDULE}icon-hot.svg`,
    title: 'So sánh kèo',
    desc_1: 'Chức năng tự động soi kèo thông minh',
    desc_2: 'Kèo Hot là kèo thắng được nhiều tiền hơn với cùng một tỉ lệ cược',
    img: `${PATH_IMG_SPORT_SCHEDULE}note-hot.webp`
  }
}

export const EMPTY_DATA = {
  icon: `${PATH_ICON_HOT_URL}sport-empty.webp`,
  text: 'Không tìm thấy trận đấu'
}

export const FOOTER = {
  TITLE: `${process.env.BRANCH_NAME} - Nhà cái cá cược uy tín nhất Châu Á, nạp rút siêu tốc.`,
  DESC: `Nhà cái ${process.env.BRANCH_NAME} là trang cược bóng đá nhanh nhất trên thị trường hiện nay, hoàn trả mỗi ngày cao nhất 1.8%. ${process.env.BRANCH_NAME} cung cấp hai loại bảng kèo K-Sports; C-Sports và tỷ lệ cược liên tục cập nhật từng giây. Lịch thi đấu mỗi
  ngày, phân tích trận đấu, soi kèo banh Ngoại Hạnh Anh, Cúp C1, Serie A, Laliga, Bundesliga, Ligue 1,... và tất
  cả các giải lớn nhỏ khác. Trải nghiệm mượt mà, mọi lúc mọi nơi.`
}

export const DAYS_TEXT = ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7']
export const TODAY = 'Hôm nay'
export const TOMORROW = 'Ngày mai'
export const PLACEHOLDER = {
  SEARCH: 'Tìm kiếm giải đấu'
}
export const EMPTY_DATA_SEARCH = 'Không tìm thấy giải đấu nào !'

export const NCC_TYPES = {
  KSPORTS: 'ksports',
  CSPORTS: 'csports',
  SSPORTS: 'ssports',
  MSPORTS: 'msports',
  TSPORTS: 'tsports',
  VIRTUAL_SPORTS: 'the-thao-ao',
  ESPORTS: 'esports',
  PSPORTS: 'psports',
  ISPORTS: 'isports',
  ASPORTS: 'asports'
}
